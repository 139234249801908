<template>
  <div class="main-box">
    <div class="content-main-bottom" >
        <div class="btm-left ">
          <div class="btm-left-head">
            <a-tabs :default-active-key="activeKey" type="line" :animation="true" size="large" style="font-size: 20px;">
              <a-tab-pane class="order-tab" :key="index" v-for="item,index in tabs" >
                <template #title>
                  <span :style="{ fontSize: '16px' }">{{item.label}}</span>
                </template>
                <div>
                  <div class="kline" v-if="item.id == 1">
                    <!-- 共富基金 -->
                    <notdate> </notdate>
                  </div>
                  <div class="kline" v-if="item.id == 2">
                    <!-- 奔富理财 -->
                    <notdate> </notdate>
                  </div>
                  <div class="kline" v-if="item.id == 3">
                    <!-- 双币理财 -->
                    <notdate> </notdate>
                  </div>
                  <div class="kline" v-if="item.id == 4">
                    <!-- 活币宝 -->
                    <div>
                      <div class="tag just">
                      <div>
                        <span>申购</span>
                        <span>赎回</span>
                        <span>利息发放</span>
                      </div>
                      <div>
                        <a-space>
                          <a-button type="primary">前往申购</a-button>
                        </a-space>
                      </div>
                      </div>
                    </div>
                    <div class="kline-box">
                       <!-- 格子B -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                    <!-- 格子A -->
                    <div class="kline-content">
                      <div class="kline-title">开始时间</div>
                      <div> 
                        <a-range-picker
						:placeholder="[$t('tim.a1'), $t('tim.a2')]"
                          @change="onChange"
                          @select="onSelect"
                          :style="{backgroundColor: '#1d2126',marginRight:'16px'}"
                          size="large"
                        ></a-range-picker>
                      </div>
                    </div>
                   
                  </div>
                    <notdate> </notdate>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import notdate from "@/components/notdate/notdate.vue";
export default{
  components:{
    notdate
  },
  data(){
    return {
      tabs: [
        {
          id:'1',
          label:'共富基金'
        },
        {
          id:'2',
          label:'Benfu Wealth Management'
        },
        {
          id:'3',
          label:'双币理财'
        },
        {
          id:'4',
          label:'活币宝'
        }
      ],
      activeKey:0
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./subscriptionHistory.scss";
</style>