<template>
    <a-layout class="help">
        <a-layout-header>
            <page-nav></page-nav>
        </a-layout-header>
        <a-layout-content class="help-body">
            <div class="banner">
                <div class="banner-content">
                    <h1>{{ $t('help.h1') }}</h1>
                    <a-input class="search" :placeholder="$t('help.h2')" allow-clear>
                        <template #prefix>
                            <icon-search size="18" class="color-gray"/>
                        </template>
                    </a-input>
                </div>
            </div>
            <!--      自助服务-->
            <!--<div class="label">
                <div class="label-content">
                    <p class="title">{{ $t('help.h3') }}</p>
                    <div class="tagButtons">
                        <div class="button" v-for="(item,index) in selfList" :key="index"
                             @click="$router.push('/categories?params='+item.id)">
                            <img :src="require(`@/assets/images/help/image${index+1}.png`)" alt="">
                            <span>{{ item.subtitle }}</span>
                        </div>
                    </div>
                </div>
            </div>-->
            <!--      公告中心-->
            <!--<div class="label">
                <div class="label-content">
                    <p class="title">{{ $t('help.h4') }}</p>
                    <div class="list">
                        <div class="item" v-for="(item,index) in noticeList" :key="index"
                             @click="$router.push({ path: '/categories', query: { parent_id: item.parent_id, cate_id: item.id } })">
                            <img :src="require(`@/assets/images/help/notice${index+1}.png`)" alt="">
                            <p>{{ item.name }}</p>
                        </div>
                    </div>
                </div>
            </div>-->
            <!--      产品资讯-->
            <!--<div class="label">
                <div class="label-content">
                    <p class="title">{{ $t('help.h5') }}</p>
                    <div class="list infomationList">
                        <div class="item" v-for="(item,index) in infomationList" :key="index"
                             @click="$router.push({ path: '/categories', query: { parent_id: item.parent_id, cate_id: item.id } })">
                            <img :src="require(`@/assets/images/help/infomation${index+1}.png`)" alt="">
                            <p>{{ item.name }}</p>
                        </div>
                    </div>
                </div>
            </div>-->
            <!--      最新活动-->
            <div class="label">
                <div class="label-content">
                    <p class="title">Introduction Notice<!--{{ $t('help.h6') }}--></p>
                    <div class="columnList">
                        <div class="columnItem" v-for="(item,index) in activeList" :key="index" @click="$router.push('/categories?params='+item.id)">
                            <div class="cell">
                                <span class="title">{{ item.title }}</span>
                            </div>
                            <div class="cell">
                                <a-tag color="var(--opacity-gray)">
                                    <span class="color-black">{{ item.subtitle }}</span>
                                </a-tag>
                                <span class="color-gray">{{ item.created_at }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <img :src="require(`@/assets/images/help/index_help_${userTheme}.png`)" alt="">
                <div class="info">
                    <p>{{ $t('help.h7') }}</p>
                    <p>{{ $t('help.h8') }}</p>
                    <a-button class="button"><a href="https://service.mocmco.com/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=12&groupid=0&special=44&theme=7571f9" target="_blank" class="boxList">{{ $t('help.h9') }}</a></a-button>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>

<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import {mapGetters} from "vuex";
import commonApi from '@/common/api/common';
import cmsApi from '@/common/api/cms';

export default {
    components: {
        PageNav,
        Footer
    },
    data() {
        return {
            selfList: [],
            noticeList: [],
            activeList:[],
            infomationList: [],
        }
    },
    computed: {
        ...mapGetters(['userTheme'])
    },
    created() {
        this.get_notice_list()
        this.get_xs_list()
        this.get_active_list()
        this.get_mation_list()
    },
    methods: {
        get_info() {
            let info = cmsApi.info();
            console.log(info);
        },
        //公告中心
        async get_notice_list() {
            let notice = await cmsApi.child_catelist(1);
            this.noticeList = notice.data
            //console.log(this.noticeList);
        },
        //产品资讯
        async get_mation_list() {
            let ma_list = await cmsApi.child_catelist(3);
            this.infomationList = ma_list.data
        },
        //新手教程
        async get_xs_list() {
            let xs_list = await cmsApi.list(2);
            this.selfList = xs_list.data.list
        },
        //最新活动
        async get_active_list() {
            let act_list = await cmsApi.list(22);
            this.activeList = act_list.data.list
        },

    }
}

</script>