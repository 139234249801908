<!-- 身份认证 -->
<template>
    <div class="auth">
        <template v-if="idAuth">
            <a-breadcrumb>
                <!--<a-breadcrumb-item>ISITE</a-breadcrumb-item>-->
                <a-breadcrumb-item>{{ $t('rn.a064') }}</a-breadcrumb-item>
                <a-breadcrumb-item>{{ $t('rn.a065') }}</a-breadcrumb-item>
            </a-breadcrumb>
            <h1>{{ $t('rn.a065') }}</h1>
            <div class="auth-body">
                <div class="_left">
                    <a-scrollbar class="card-scroll">
                        <div class="label" v-for="setting in setting" :key="setting.key">
                            <div class="_title-box">
                                <div class="flex align-center">
                                    <div class="img-box" v-if="setting.ifBind">
                                        <svg viewBox="0 0 1024 1024" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="25" height="25">
                                            <path
                                                d="M431.8 708.8L206.3 483.4c-12.5-12.5-12.5-32.7 0-45.2s32.7-12.5 45.2 0l225.4 225.4c12.5 12.5 12.5 32.7 0 45.2-12.4 12.5-32.7 12.5-45.1 0z"
                                                fill="var(--content-white)">
                                            </path>
                                            <path
                                                d="M432.4 709.5c-12.6-12.6-12.6-33.2 0-45.8l337.3-337.3c12.6-12.6 33.2-12.6 45.8 0 12.6 12.6 12.6 33.2 0 45.8L478.2 709.5c-12.6 12.7-33.1 12.7-45.8 0z"
                                                fill="var(--content-white)">
                                            </path>
                                        </svg>
                                    </div>
                                    <div class="_row" @click="setting.openCard = !setting.openCard">
                                        <span>{{ setting.name }}</span>
                                        <a-tag class="tag"
                                               :color="setting.ifBind ? 'var(--content-trade-buy)' : 'var(--background-secondary)'">
                                        <span :class="setting.ifBind ? 'color-black' : 'color-white'">{{
                                                setting.status.name
                                            }}</span>
                                        </a-tag>
                                        <div class="down-box" :class="{'open-down': setting.openCard}">
                                            <icon-caret-down/>
                                        </div>
                                    </div>
                                </div>
                                <a-button class="button"
                                          v-if="setting.ifBind == false"
                                          @click="goAuthentication(setting)">
                                    {{ setting.btn }}
                                </a-button>
                            </div>
                            <div class="auth-info" :class="{'not-open': setting.openCard == false}">
                                <div class="cell">
                                    <span>{{ setting.des }}</span>
                                </div>
                                <template v-if="setting.openCard">
                                    <div class="cell">
                                        <span>{{ $t('rn.a066') }}</span>
                                        <span>{{ $t('son.a01') }}</span>
                                    </div>
                                    <div class="cell">
                                        <span>{{ $t('son.a02') }}</span>
                                        <span>3M USDT</span>
                                    </div>
                                    <div class="cell">
                                        <span>{{ $t('son.a03') }}</span>
                                        <img src="@/assets/images/personal_img/allow.svg" alt="">
                                    </div>
                                    <div class="cell">
                                        <span>{{ $t('son.a04') }}</span>
                                        <img src="@/assets/images/personal_img/allow.svg" alt="">
                                    </div>
                                    <div class="cell">
                                        <span>{{ $t('son.a05') }}</span>
                                        <img src="@/assets/images/personal_img/allow.svg" alt="">
                                    </div>
                                    <div class="cell">
                                        <span>{{ $t('son.a06') }}</span>
                                    </div>
                                    <div class="cell">
                                    <span>
                                        <svg viewBox="0 0 1031 1024" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path
                                            d="M857.6 928 166.4 928c-89.6 0-166.4-70.4-166.4-166.4L0 262.4c0-89.6 70.4-166.4 166.4-166.4l697.6 0c89.6 0 166.4 70.4 166.4 166.4l0 505.6C1024 857.6 953.6 928 857.6 928zM166.4 160C108.8 160 64 204.8 64 262.4l0 505.6c0 57.6 44.8 102.4 102.4 102.4l697.6 0c57.6 0 102.4-44.8 102.4-102.4L966.4 262.4c0-57.6-44.8-102.4-102.4-102.4L166.4 160z"></path><path
                                            d="M364.8 576c-76.8 0-140.8-64-140.8-140.8 0-76.8 64-140.8 140.8-140.8s140.8 64 140.8 140.8C505.6 518.4 441.6 576 364.8 576zM364.8 358.4C320 358.4 281.6 396.8 281.6 435.2S320 512 364.8 512c44.8 0 76.8-32 76.8-76.8S403.2 358.4 364.8 358.4z"></path><path
                                            d="M576 742.4c-12.8 0-25.6-12.8-32-25.6C518.4 595.2 422.4 576 364.8 576S211.2 595.2 192 716.8c-6.4 19.2-19.2 25.6-38.4 25.6C134.4 736 121.6 723.2 128 704c25.6-115.2 115.2-192 236.8-192s217.6 70.4 236.8 192c6.4 19.2-6.4 32-25.6 38.4C576 742.4 576 742.4 576 742.4z"></path><path
                                            d="M864 448l-192 0C652.8 448 640 435.2 640 416S652.8 384 672 384l192 0C883.2 384 896 396.8 896 416S883.2 448 864 448z"></path><path
                                            d="M736 601.6l-64 0c-19.2 0-32-12.8-32-32s12.8-32 32-32l64 0c19.2 0 32 12.8 32 32S755.2 601.6 736 601.6z"></path></svg>
                                        {{ $t('son.a07') }}
                                    </span>
                                    </div>
                                    <div class="cell">
                                    <span>
                                        <svg viewBox="0 0 1024 1024" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" p-id="25813" width="18" height="18"><path
                                            d="M319.95 362.61h63.99v85.32h-63.99v-85.32zM639.9 106.65h181.305c53.011 0 95.985 42.974 95.985 95.985V383.94h-64.033l0.043-181.305c0-16.63-12.689-30.298-28.914-31.849l-184.386-0.043V106.65z m-255.96 0v64.033l-181.305-0.043c-16.63 0-30.298 12.689-31.849 28.914l-0.043 184.386H106.65V202.635c0-53.011 42.974-95.985 95.985-95.985H383.94zM639.9 917.19v-64.033l181.305 0.043c16.63 0 30.298-12.689 31.849-28.914l0.043-184.386h64.093v181.305c0 53.011-42.974 95.985-95.985 95.985H639.9z m-255.96 0H202.635c-53.011 0-95.985-42.974-95.985-95.985V639.9h64.033l-0.043 181.305c0 16.63 12.689 30.298 28.914 31.849l184.386 0.043v64.093zM639.9 362.61h63.99v85.32H639.9v-85.32zM387.522 671.854l-26.621-17.748 35.495-53.243 26.622 17.748c20.889 13.926 50.34 21.289 88.902 21.289 38.561 0 68.013-7.363 88.902-21.289l26.622-17.748 35.495 53.243-26.621 17.748c-32.436 21.624-74.084 32.036-124.398 32.036-50.314 0-91.962-10.412-124.398-32.036z"></path></svg>
                                        {{ $t('son.a08') }}
                                    </span>
                                    </div>
                                    <div class="cell">
                                    <span>
                                        <svg viewBox="0 0 1024 1024" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg" p-id="26835" width="18" height="18"><path
                                            d="M511.65 961C265.6 961 64.3 759.26 64.3 512.7S265.6 64.41 511.65 64.41 959 266.14 959 512.7 757.69 961 511.65 961zM656 856.61a377.28 377.28 0 0 0 198.65-199.12 371.82 371.82 0 0 0 0-289.58A377.18 377.18 0 0 0 656 168.79a369.15 369.15 0 0 0-288.64 0 377.09 377.09 0 0 0-198.71 199.12 371.82 371.82 0 0 0 0 289.58A377.12 377.12 0 0 0 367.33 856.6a369.21 369.21 0 0 0 288.64 0z"></path><path
                                            d="M473 555m0-37.5l0-249q0-37.5 37.5-37.5l0 0q37.5 0 37.5 37.5l0 249q0 37.5-37.5 37.5l0 0q-37.5 0-37.5-37.5Z"></path><path
                                            d="M796 554m-37.5 0l-249 0q-37.5 0-37.5-37.5l0 0q0-37.5 37.5-37.5l249 0q37.5 0 37.5 37.5l0 0q0 37.5-37.5 37.5Z"></path></svg>
                                       {{ $t('son.a09') }} 
                                    </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </a-scrollbar>
                </div>
                <div class="_right">
                    <div class="card">
                        <div class="title">
                            <span>{{ $t('son.a010') }} </span>
                            <div class="down-box" @click="infoShow = !infoShow">
                                <icon-caret-down/>
                            </div>
                        </div>
                        <template v-if="infoShow">
                            <div class="cell">
                                <span>{{ $t('son.a011') }}</span>
                                <span>XXX</span>
                            </div>
                            <div class="cell">
                                <span>{{ $t('son.a012') }}</span>
                                <span>XXX</span>
                            </div>
                            <div class="cell">
                                <span>{{ $t('son.a013') }}</span>
                                <span>********</span>
                            </div>
                        </template>
                    </div>
                    <div class="card">
                        <div class="title">
                            <span>FAQ</span>
                            <span>
                            {{ $t('son.a014') }}
                            <icon-right/>
                        </span>
                        </div>
                        <a-scrollbar class="faq-scroll">
                            <div class="cell-lb"
                                 :class="{'hideContent': item.openAll === false}"
                                 @click="item.openAll = !item.openAll"
                                 v-for="item in faqOptions">
                                <div class="_top">
                                    <span>{{ item.title }}</span>
                                    <icon-caret-down />
                                </div>
                                <div class="_content">
                                    <p>{{ item.desc }}</p>
                                    <p v-if="item.desc1">{{ item.desc1 }}</p>
                                </div>
                            </div>
                        </a-scrollbar>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="foundation">
            <foundationAuth @safet-event="goBack"></foundationAuth>
        </template>
        <template v-if="senior">
            <seniorAuth @safet-event="goBack"></seniorAuth>
        </template>
    </div>
    <div class="main-box" v-if="false">
        <div class="content-main-bottom">
            <div class="btm-left">
                <div class="btm-left-head">
                    <div class="user-management">
                        <div class=" user-management-header">
                        </div>
                        <div class="user-management-content">
                            <div class="title">
                                 {{ $t('son.a015') }}
                            </div>
                            <div class="safe-setting" v-for="setting in setting" :key="setting.key">
                                <div class="box">
                                    <div class="flex">
                                        <img class="email"
                                             :src="require('@/assets/images/personal_img/management/' + setting.icon + '.png')"
                                             alt="">

                                        <div class="user-management-left">
                                            <div class="m-title">{{ setting.name }}</div>
                                            <div class="des">{{ setting.des }}</div>
                                        </div>
                                    </div>
                                    <div class="middle">
                                        <div class="point"
                                             v-if="setting.ifBind ? setting.status.color === '#ff9e2d' : setting.status.color === '#ff9e2d'"
                                             :style="{ backgroundColor: setting.status.color }"></div>
                                        <div class="cont">
                                            {{ setting.status.name }}
                                        </div>
                                    </div>
                                    <div>
                                        <a-button type="primary" size="large" @click="goAuthentication(setting)">
                                            {{ setting.btn }}
                                        </a-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- component start-->
    <div>

    </div>
    <!-- component end -->
</template>

<script>
import foundationAuth from '../foundationAuth/foundationAuth.vue'
import seniorAuth from '../seniorAuth/seniorAuth.vue'

export default {
    components: {
        foundationAuth,
        seniorAuth
    },
    data() {
        return {
            setting: [
                {
                    name: this.$t('son.a016'),
                    des: this.$t('son.a017'),
                    icon: 'idAuth',
                    status: {
                        name: this.$t('son.a018'),
                        color: '#ff9e2d'
                    },
                    btn: this.$t('son.a019'),
                    type: 'primary',
                    ifBind: false,
                    key: 0,
                    openCard: false
                },
                {
                    name: this.$t('son.a020'),
                    des: this.$t('son.a021'),
                    icon: 'idAuth',
                    status: {
                        name: this.$t('son.a018'),
                        color: '#ff9e2d'
                    },
                    btn: this.$t('son.a019'),
                    type: 'primary',
                    ifBind: false,
                    key: 1,
                    openCard: false
                }
            ],
            idAuth: true,
            foundation: false,
            senior: false,
            infoShow: true,
            faqOptions: [{
                title: this.$t('son.a022'),
                desc: this.$t('son.a023'),
                openAll: false
            }, {
                title: this.$t('son.a024'),
                desc: this.$t('son.a025'),
                openAll: false
            }, {
                title: this.$t('son.a026'),
                desc: this.$t('son.a027'),
                desc1: this.$t('son.a028'),
                openAll: false
            }, {
                title: this.$t('son.a029'),
                desc: this.$t('son.a030'),
                desc1: this.$t('son.a031'),
                openAll: false
            }]
        };
    },
    methods: {
        goAuthentication(e) {
            console.log(e)
            if (e.key == 0) {
                console.log(e.key)
                this.foundation = true
                this.idAuth = false
                this.senior = false
            } else if (e.key == 1) {
                this.foundation = false
                this.idAuth = false
                this.senior = true
            }
        },
        goBack() {
            this.foundation = false
            this.senior = false
            this.idAuth = true
        },
    },
};
</script>
<style lang="scss" scoped>
@import "./isAuth.scss";
</style>
  