<template>
  <a-layout class="term">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="term-content">
        <!--  banner    -->
        <div class="banner">
          <div class="banner-content">
            <div class="_left">
              <h1>{{$t('us.z23')}}</h1>
              <p></p>
            </div>
            <div class="_right">
              <div v-if="isLogin" class="account">
                <div class="head">
                  <div class="row">
                    <span class="color-gray font-12 cursor">定期持仓估值 <icon-caret-down class="cursor"/></span>
                    <icon-eye class="cursor color-gray"/>
                  </div>
                  <div class="row">
                    <span class="font-18 color-black font-bold">-- USDT</span>
                  </div>
                  <div class="row">
                    <span class="font-12 color-gray">累计收益(USDT)</span>
                  </div>
                  <div class="row">
                    <span class="font-14 color-black">0.00000000</span>
                  </div>
                </div>
                <div class="bottom">
                  <a-button class="button">前往账户</a-button>
                  <a-button class="button">前往历史</a-button>
                </div>
              </div>
              <div v-else class="not-log" style="display: none;">
                <svg width="50" height="50" viewBox="0 0 66 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M52.962 3.83c3.23 4.245 2.435 10.285-1.776 13.49-4.212 3.204-10.245 2.36-13.475-1.886-3.23-4.245-2.435-10.285 1.776-13.49C43.7-1.26 49.732-.415 52.962 3.83Z"
                      fill="#9C69FF"></path>
                  <path
                      d="M23.228 29.47c-4.163-5.471-3.138-13.254 2.29-17.383 5.426-4.13 13.2-3.042 17.363 2.429 4.163 5.47 3.138 13.253-2.29 17.382-5.426 4.13-13.2 3.042-17.363-2.428Z"
                      fill="#05C3DD"></path>
                  <g filter="url(#assetLogin_svg__a)">
                    <rect x="9" y="22" width="47" height="33" rx="1.82" fill="url(#assetLogin_svg__b)"
                          fill-opacity="0.5"></rect>
                    <rect x="8.75" y="21.75" width="47.5" height="33.5" rx="2.07" stroke="#393939"
                          stroke-width="0.5"></rect>
                  </g>
                  <path fill="#05C3DD" d="M47.233 35.597H58v6.63H47.233z"></path>
                  <defs>
                    <linearGradient id="assetLogin_svg__b" x1="10.152" y1="32.033" x2="15.541" y2="7.514"
                                    gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B8B8B8"></stop>
                      <stop offset="1" stop-color="#393939" stop-opacity="0"></stop>
                    </linearGradient>
                    <filter id="assetLogin_svg__a" x="0.191" y="13.191" width="64.618" height="50.618"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.154"></feGaussianBlur>
                      <feComposite in2="SourceAlpha" operator="in"
                                   result="effect1_backgroundBlur_2497_16220"></feComposite>
                      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_2497_16220" result="shape"></feBlend>
                    </filter>
                  </defs>
                </svg>
                <p>{{$t('us.z26')}}</p>
                <a-button class="button">{{$t('us.a54')}}</a-button>
              </div>
            </div>
          </div>
        </div>
        <!--    main  -->
        <div class="f-main-wrap flex flex-column align-center jus-center">
          <div class="f-main-con">
            <!--            -->
            <div class="_top flex align-center jus-bet">
				
              <div class="_top-left flex align-center">
				  
                <div class="cell" :class="menuActive === 0 ? 'cell-active' : ''" @click="menuActives(0)">
					<span>Novice Zone</span>
				</div>
				
                <div class="cell" :class="menuActive === 1 ? 'cell-active' : ''" @click="menuActives(1)">
					<span>Welfare Zone</span>
				</div>
				
                <div class="cell" :class="menuActive === 2 ? 'cell-active' : ''" @click="menuActives(2)">
					<span>Stable Zone</span>
				</div>
				
<!--                <img @click="toEary" src="@/assets/images/term/termAd-cn.png">-->
              </div>
              <div class="_top-right flex align-center pointer">
                <a-input class="search" :placeholder="$t('us.a70')">
                  <template #prefix>
                    <icon-search />
                  </template>
                </a-input>
              </div>
            </div>
            <!--              -->
            <div class="_list">
              <div class="list-tab">
                <span>{{$t('per.s8')}}</span>
                <span>{{$t('r.a15')}}</span>
                <span>{{$t('dc.dqa2')}}</span>
                <span>{{$t('dc.dqa1')}}</span>
                <span>{{$t('per.s72')}}</span>
              </div>
              <div class="list-con">
                <div class="cell-box pointer" v-for="(item,index) in coinList" :key="index" @click="toDetail(index)">
                  <div class="box-con flex align-center jus-bet">
                    <div class="c-item flex align-center">
                      <img :src="require('@/assets/images/coin/' + item.type + '.png')"/>
                      <span class="uppercase"> {{ item.type }}</span>
                    </div>
                    <div class="c-item flex align-center">
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="c-item flex align-center">
                      <span class="color-green">{{ item.rate }}</span>
                    </div>
                    <div class="c-item flex align-center">
                      <span>{{ item.day }}</span>
                    </div>
                    <div class="c-item flex align-center">
					  <a-button class="button" @click="openurl('https://isite.takebb.org/app/')">{{$t('dc.dqa3')}}</a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          -->
          <!--<div class="f-main-bottom flex flex-column align-start" style="display: none;">
            <p class="_title">ISITE奔富理财Q&A</p>
            <div class="collapse-box">
              <a-collapse expand-icon-position="right" :default-active-key="['1']">
                <a-collapse-item header="1、什么是ISITE奔富理财？" key="1">
                  <p>ISITE奔富理财是ISITE
                    Exchange推出的封闭式保本型数字货币理财产品（封闭周期为7天到3年不等），具有年化收益高、可抵御市场行情风险、投资灵活等优势，能够满足用户保本保息的投资需求。</p>
                </a-collapse-item>
                <a-collapse-item header="2、如何申购理财产品？" key="2">
                  <p>用户可在奔富理财列表中看到可申购币种、预期年化收益及投资期限，可根据实际投资需求选择最优投资方案。</p>
                </a-collapse-item>
                <a-collapse-item header="3、收益如何计算？" key="3">
                  <p>以“180天优享版产品”为例（各产品交易规则的描述效力高于本FAQ），ISITE奔富理财的收益计算方式如下：</p>
                  <p>（1）申购发起日为T日，T+1日12:00开始处理申购订单，订单确认后开始计息；</p>
                  <p>
                    （2）到期赎回时会返还扣除手续费的本金和最后一期利息，即赎回金额=申购本金+申购本金*年化利率/365-申购本金*申购手续费-申购本金*赎回手续费；</p>
                  <p>（3）利息将在应计第二天开始发放，即每日发放的利息=申购本金*年化利率/365。</p>
                  <p>举例，用户A在1月1号申购了1000USDT，期限7天、年化利率为10%的产品，无手续费。</p>
                  <p>在1月2号12:00系统会确认该笔申购（10:00前用户可撤销该笔申购）。</p>
                  <p>在1月3号12:00系统会发放第一笔利息1000*0.1/365=0.2739USDT。</p>
                  <p>在1月4号12:00系统发放第二笔利息0.2739USDT。</p>
                  <p>接下来每日，系统都将发放利息0.2739USDT。</p>
                  <p>直到1月9号12:00系统进行自动赎回，9号当天发放本金和当日利息1000+0.2739=1000.2739USDT。</p>
                  <p>至此该笔申购结束，用户A共可得到1001.9173USDT。</p>
                </a-collapse-item>
                <a-collapse-item header="4、支持哪些数字货币？" key="4">
                  <p>
                    ISITE奔富理财提供多种数字货币理财服务，您可在产品页面中查看当前支持的理财币种，平台将定期进行更新。</p>
                </a-collapse-item>
                <a-collapse-item header="5、如何赎回理财产品？" key="5">
                  <p>
                    不同的理财产品有不同的赎回规则。例如封闭式理财产品不支持提前赎回，在持有期间系统会按照约定发放利息，或按照约定期日发放全额本息。开放式理财一般可在投资期限内进行转让或提前赎回，但需支付相应手续费。目前部分产品已支持自动复购功能。</p>
                </a-collapse-item>
                <a-collapse-item
                    header="6、为什么ISITE质押理财产品的订单收益有时候相比昨日会下降，甚至持续几天为零收益？" key="6">
                  <p>
                    因为ISITE质押理财产品属于币本位保本浮动收益型产品，其收益来源于量化策略投资，存在净值回撤甚至亏损的可能性。净值若相比昨日出现回撤，订单收益相比昨日也将下降；如果当前净值低于订单申购时的净值，该笔订单资金处于亏损状态，亏损由平台承担，因而用户订单收益展示为0。</p>
                </a-collapse-item>
              </a-collapse>
            </div>
          </div>-->
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import Footer from "@/components/Footer/footer.vue";
import PageNav from "@/components/PageNav/PageNav.vue";
import notlog from "@/components/notlog/notlog.vue";

export default {
  components: {notlog, PageNav, Footer},
  data() {
    return {
	  menuActive:0,
      coinList: [{
        is: "Novice Zone",
        type: "usdt",
        name: "Silver Financial Management",
        rate: "1.48%",
        day: "5 Day"
      }, {
        is: "Novice Zone",
        type: "usdt",
        name: "Iron Ore Financial Management",
        rate: "1.76%",
        day: "9 Day"
      }, ]
    }
  },
  methods: {
	  openurl(url) {
	      window.open(url);
	  },
	  menuActives(index){
		  this.menuActive = index;
		  if(this.menuActive==0){
			  this.coinList = [{
				is: "Novice Zone",
				type: "usdt",
				name: "Silver Financial Management",
				rate: "1.48%",
				day: "5 Day"
			  }, {
				is: "Novice Zone",
				type: "usdt",
				name: "Iron Ore Financial Management",
				rate: "1.76%",
				day: "9 Day"
			  },];
		  }
		  if(this.menuActive==1){
			  this.coinList = [{
				is: "Welfare Zone",
				type: "usdt",
				name: "Hong Kong Hang Seng Index",
				rate: "2.25%",
				day: "28 Day"
			  }, {
				is: "Welfare Zone",
				type: "usdt",
				name: "Crude oil wealth management",
				rate: "2.55%",
				day: "48 Day"
			  }];
		  }
		  
		  if(this.menuActive==2){
			  this.coinList = [{
				is: "Stable Zone",
				type: "usdt",
				name: "Gold Wealth Management",
				rate: "1.68%",
				day: "60 Day"
			  }, {
				is: "Stable Zone",
				type: "usdt",
				name: "BTC Financial Management",
				rate: "2.22%",
				day: "150 Day"
			  }, {
				is: "Stable Zone",
				type: "usdt",
				name: "German Index Financial Management",
				rate: "1.88%",
				day: "100 Day"
			  }, {
				is: "Stable Zone",
				type: "usdt",
				name: "US Index Financial Management",
				rate: "2.99%",
				day: "360 Day"
			  }, {
				is: "Stable Zone",
				type: "usdt",
				name: "Ethereum Wealth Management",
				rate: "1.58%",
				day: "45 Day"
			  }];
		  }
		  
	  },
    toEary() {
      this.$router.push("/HomeView/investment/easyEarn")
    },
    toDetail(index) {
		return false;
      if (index == 3) {
        this.$router.push("/HomeView/investment/pledge")
      } else {
        this.$router.push("/HomeView/investment/Term/detail")
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./term.scss";
</style>