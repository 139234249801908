<template>
  <div class="footer-wrap">
    <div class="footer-default">
      <div class="left">
        <h1 class="footer-logo">
          <img src="@/assets/logo1.png" alt="" width="80" height="30">
        </h1>
        <p>
          {{ $t('footer.f1') }}</p>
        <div class="relation">
          <a href="javascript:;">
            <img src="@/assets/icon1.png" alt="">
          </a>
          <a href="javascript:;">
            <img src="@/assets/icon2.png" alt="">
          </a>
          <a href="javascript:;">
            <img src="@/assets/icon3.png" alt="">
          </a>
          <a href="javascript:;">
            <img src="@/assets/icon4.png" alt="">
          </a>
          <a href="javascript:;">
            <img src="@/assets/icon5.png" alt="">
          </a>
          <a href="javascript:;">
            <img src="@/assets/icon6.png" alt="">
          </a>
        </div>
      </div>
      <div class="right">
        <div class="menu">
          <div class="item">
            <p class="title">{{ $t('footer.f2') }}</p>
            <p class="txt">
              <a href="javascript:;"  @click="$router.push('/categories?params=24')">
                {{ $t('footer.f6') }}
                <icon-right />
              </a>
            </p>
            <!--<p class="txt">
              <a href="javascript:;">{{ $t('footer.f7') }}<icon-right /></a>
            </p>-->
            <p class="txt">
              <a href="javascript:;" @click="$router.push('/categories?params=496')">{{ $t('footer.f8') }}<icon-right /></a>
            </p>
            <p class="txt">
              <a href="javascript:;" @click="$router.push('/categories?params=20')">{{ $t('footer.f9') }}<icon-right /></a>
            </p>
            <!--<p class="txt">
              <a href="javascript:;">{{config['site_name']}} Meta<icon-right /></a>
            </p>-->
          </div>
          <div class="item">
            <p class="title">{{ $t('footer.f3') }}</p>
            <p class="txt">
              <a href="javascript:;" @click="$router.push('/categories?params=17')">{{ $t('footer.f10') }}<icon-right /></a>
            </p>
            <p class="txt">
              <a href="javascript:;"  @click="$router.push('/categories?params=16')">{{ $t('footer.f11') }}<icon-right /></a>
            </p>
            <p class="txt">
              <a href="javascript:;"  @click="$router.push('/categories?params=21')">{{ $t('footer.f12') }}<icon-right /></a>
            </p>
          </div>
          <div class="item">
            <p class="title">{{ $t('footer.f4') }}</p>
            <p class="txt">
              <a href="javascript:;" @click="$router.push('/categories?params=29')">{{ $t('footer.f13') }}<icon-right /></a>
            </p>
            <!-- <p class="txt">
              <a href="javascript:;">{{ $t('footer.f14') }}<icon-right /></a>
            </p>-->
<!--            <p class="txt">-->
<!--              <a href="javascript:;">API文档<icon-right /></a>-->
<!--            </p>-->
            <p class="txt">
              <a href="javascript:;"  @click="$router.push('/categories?params=15')">{{ $t('footer.f15') }}<icon-right /></a>
            </p>
            <!--<p class="txt">
              <a href="javascript:;">Launchpad<icon-right /></a>
            </p>-->
          </div>
          <div class="item">
            <p class="title">{{ $t('footer.f5') }}</p>
            <p class="txt">
              <a href="javascript:;" @click="$router.push('/help')">{{ $t('footer.f16') }}<icon-right /></a>
            </p>
            <p class="txt">
              <a href="javascript:;"  @click="$router.push('/categories?params=25')">{{ $t('footer.f17') }}<icon-right /></a>
            </p>
            <p class="txt">
              <a href="https://isite.takebb.org/app/">{{ $t('footer.f18') }}<icon-right /></a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="btm">
      <p>
        <span>{{config['site_name']}} © 2020-2024</span>
        All Rights Reserved by {{config['site_name']}} Group. U.S. MSB License NO. : 31000219137978
      </p>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
  mapMutations
} from 'vuex';

export default {
  name: "Footer",
  computed:{
    ...mapState(['config', 'userinfo']),
  }
}
</script>
<style lang="scss" scoped>
.footer-wrap {
  width: 100%;
  height: auto;
  background-color: var(--background-primary);

  .footer-default {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 72px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;

      .footer-logo {
        width: 95px;
        height: 40px;
      }

      p {
        margin-top: 36px;
        width: 323px;
        word-break: break-word;
        font-size: 14px;
        color: var(--content-tertiary);
        line-height: 22px;
        text-align: left;
      }

      .relation {
        display: flex;
        align-items: center;
        margin-top: 50px;

        a {
          width: 20px;
          height: 20px;
          margin-right: 16px;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
      }
    }

    .right {
      .menu {
        display: flex;
        align-items: flex-start;

        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 90px;

          .title {
            letter-spacing: 0;
            line-height: 38px;
            white-space: nowrap;
            word-break: keep-all;
            font-size: 16px;
            font-weight: 600;
            color: var(--content-primary);
          }

          .txt {
            font-size: 14px;
            cursor: pointer;
            font-weight: 400;
            margin: 0;
            padding: 0;
            line-height: 38px;

            a {
              text-decoration: none;
              color: var(--content-tertiary);

              .arco-icon {
                opacity: 0;
                transition: all 0.15s linear;
              }
            }

            &:hover {
              a {
                color: var(--color-primary);
              }

              .arco-icon {
                opacity: 1;
                transform: translateX(6px);
              }
            }
          }
        }
      }
    }
  }

  .btm {
    border-top: 1px solid var(--border-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 24px 0;
    box-sizing: border-box;
    text-align: center;

    p {
      margin: 0;
      padding: 0;
      color: var(--content-primary);

      span {
        color: var(--content-primary);
      }
    }
  }
}
</style>