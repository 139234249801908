import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import notlog from "@/components/notlog/notlog.vue"
import notdate from "@/components/notdate/notdate.vue";
import fundsTransfe from "@/components/fundsTransfe/fundsTransfe.vue";
import {mapState, mapGetters} from "vuex";

import currencyApi from '@/common/api/currency';
import contractApi from '@/common/api/contract';
import {KLineChartPro} from "@klinecharts/pro";
import config from "@/common/api/config";

const currentKey = 'contract-current';

let klineCharts = false;
let _ticker = false;
let upset = false;

let firstPrice;
let last;
const time = {
    '1s':0,
    '1m':1,
    '5m':5,
    '15':15,
    '30m':30,
    '1H':60,
    '4H':240,
    '12H':720,
    '1D':1440,
    '1W':10080
}

let socket = false;
let socketInterval = false;
let amax;
let bmax;

//资金废率相关
let timecaluc = false;
let timestamp = 0;

let page = 1;

export default {
    components: {Footer, PageNav, notlog, fundsTransfe,notdate},
    data() {
        return {
			vw: window.innerWidth,
            applyTestClass: false,
            coinName: "BTC",
            coinType: "USD",
            chartType: 0, //图表类型
            chartTabIndex: 0, //图表or概述
            timeOptions: ['分时', '1分钟', '3分钟', '5分钟', '15分钟', '30分钟', '1小时', '2小时', '4小时', '6小时', '8小时', '12小时', '1天', '3天', '周线', '月线',],
            timeEdit: false,
            selectTime: ['分时', '1分钟', '3分钟', '5分钟'],
            timeActive: 0,
            priceTypeVal: '最新价格',
            tableOption: [
                {
                    name: "BTC",
                    type: "USD",
                    rate: "-0.25%"
                }
            ],
            tableActive: 0,
            aboutCoin: false,
            decimal: "0.000001",
            listType: 0,
            List: [
                {
                    price: "0.061888",
                    num: "216000",
                    total: "1985264",
                }],
            timer: "",
            btnActive: 0,
            modeOption: [this.$t('s.a52'), this.$t('po.a20'), this.$t('po.a22')],
            modeIndex: 0,
            subtabActive: 0,
            marks: {
                0: '0%',
                25: '25%',
                50: '50%',
                75: '75%',
                100: '100%'
            },
            like: false,
            marginModel: false,
            leverSlider: {
                0: '1X',
                25: '25X',
                50: '50X',
                75: '75X',
                100: '100X',
                125: '125X'
            },
            leverArm: false,
            leverVal: 20,
            balanceTip: false,
            tabIndex: 0,
            tabOption: [this.$t('r.a34'), this.$t('s.a89')],
            buying: 1,
            calculator: false, //计算器
            transfeShow: false, //划转
            checked: false,
            placeOrder: false, //下单
            closePotion: false, //平仓
            flashClose: false, //闪电平仓
            profitLossPop: false, //止盈止损
            closePotionTab: [this.$t('po.a59'), this.$t('po.a60')],
            wdOrder: false, //撤单
            orderInfoPopup: false, //订单详情
            settingDrawer: false, //设置,

            currency:[],//交易对
            current:{},//当前的交易对
            currencyTab:0,//选中的交易对
            searchVal:"",//搜索

            asks:[],
            bids:[],
            market:{},
            trades:[],
            timeInt:"",

            //仓位模式绑定
            all:true,
            signal:false,
            positionModel : 0, //0全仓 1逐仓

            balance : false, //资金余额,

            //下单参数
            price:"", //下单价格
            wprice:"", //触发价格
            amount:"", //下单数量
            amounType:1, //数量类型
            limitType:0, //触发价格是 0 限价 1市场价
            rate : 0,

            margin:'0.00',
            submitDirection:false,
            loading:false,

        //     仓位
            position:{position:[],entrust:[],entrustP:[]},

            pindex:false ,//操作平仓的索引

            pamount : '', //平仓数量
            pprice : '', //平仓价格,

            wptype:0, //止盈平仓类型
            wamount :"",//止盈平仓数量
            wpprice:"",//止盈平仓价格
            wpwprice:"",//止盈触发价格

            lptype:0,
            lprice:"",
            lwprice:"",
            lamount : "",//止损平仓数量

            tabPanelIndex : 1,

            history:[],

            cleverVal : 1,//计算器滑动
            cprice : "",
            cwprice:"",
            camount : "",
            currencyInfo:false,
			cm_id:2,
        }
    },
    mounted() {
        console.log("mounted");
        // this.setNum();
        this.getContractCurrency();
        this.getCurrentCurrency();

        this.getPositionModel();
        this.getPositionLever();

        this.getBalance();
        if(this.isLogin){
            this.getBalance();
            this.getPosition();
			//console.log(50);
        }
        this.checkWidthAndApplyClass();
        window.addEventListener('resize', this.checkWidthAndApplyClass);
		window.addEventListener('resize', this.resize);
		
    },
	
	
    computed: {
        ...mapState(['userTheme']),
        ...mapGetters(['isLogin','userId'])
    },
    watch:{
        positionModel(v){
            console.log("position model : ",v);
        },
        isLogin(v){
            if(v){
                this.getBalance();
                this.getPosition();
            }
        },
        modeIndex(v){
            this.price = '';
            this.amount = '';
            this.rate = 0;
        },
        amounType(v){
            this.amount = '';
            this.rate = 0;
        }
    },
    methods: {
		resize(){
			this.vw = window.innerWidth;
		},
        checkWidthAndApplyClass() {
            //console.log(window.innerWidth)
            // 此处添加逻辑判断窗口宽度并决定是否应用 test-class-name
            if (window.innerWidth <= 1580) { // 假设宽度阈值为 600px
              this.applyTestClass = true;
            } else {
                this.applyTestClass = false;
            }
        },
        closePositionByForm(direction){
            //通过操作表单平仓
            if(this.amount === '' || this.amount <= 0){
                this.$notification.info(this.$t('r.a35'));
                return false;
            }
            if(this.modeIndex !== 1 && (this.price === '' || this.price <= 0)){
                this.$notification.info(this.$t('r.a36'));
                return false;
            }
            let position = this.position.position.filter((item)=>{
                if(parseInt(item['currency_id']) === parseInt(this.current['id']) && parseInt(item['direction']) === direction){
                    return true;
                }
                return false;
            });
            if(position.length <= 0){
                this.$notification.info(this.$t('r.a37'));
                return false;
            }
            let id = position[0]['id'];
            const form = {
                id: id,
                pamount: this.$util.str2number(this.amount),
                ptype: this.modeIndex !== 1 ? 2 : 1,
                price: this.modeIndex !== 1 ? this.price : _ticker['c'],
                wprice: this.modeIndex === 2 ? this.wprice : 0
            };
            this.loading = true;
            contractApi.closeOrder(form).then(res=>{
                this.loading = false;
                this.$notification.info(res.message);
                if(res.code === 200){
                    this.getPosition();
                    this.price = '';
                    this.amount = '';
                    this.rate = 0;
                }
            })
        },
        getStopProfit(amount){
            //获取平仓数量的预估盈亏
            if(amount === '' || amount <= 0){
                return '0.00';
            }
            let price = parseFloat(this.current['new_price']);
            if(this.modeIndex === 0){
                price = parseFloat(this.price);
            }
            if(this.modeIndex === 2){
                price = parseFloat(this.price)
            }
            let longPirce = this.getPositionOpenPrice(1);
            let long = longPirce === 0 ? 0 : (price - longPirce) * amount;
            let shortPrice = this.getPositionOpenPrice(2);
            let short = shortPrice === 0 ? 0 :(shortPrice - price) * amount;
            return {'long':long,'short':short}
        },
        getPositionOpenPrice(direction){
            let price = 0;
            this.position.position.map((item)=>{
                if(parseInt(item.currency_id) === parseInt(this.current['id']) && parseInt(item['direction']) === direction && price === 0){
                    price = parseFloat(item['price']);
                }
            });
            return price;
        },
        getPositionAmount(direction){
            //平仓获取已有仓位的可平数量
            let amount = 0;
            if(this.position.position.length <= 0){
                return 0;
            }
            this.position.position.map((item)=>{
                if(parseInt(item.currency_id) === parseInt(this.current['id']) && parseInt(item.direction) === direction){
                    amount += parseFloat(item['amount']) - parseFloat(item['close']);
                }
            });
            return amount;
        },
        cancelOrder(){
            //撤单
            this.loading = true;
            let opid = false;
            if(this.tabPanelIndex === 2){
                opid = this.position.entrust[this.pindex]['id']
            }
            if(this.tabPanelIndex === 3){
                opid = this.position.entrustP[this.pindex]['id'];
            }
            contractApi.cancelOpeartion(opid).then(res=>{
                this.loading = false;
                this.wdOrder = false;
                this.$notification.info(res.message);
                this.getPosition();
            });
        },
        wlProfitSet(){
            //止盈止损设置提交
            if(this.wamount === '' || this.wamount <= 0){
                this.$notification.info(this.$t('r.a37'));
                return false;
            }
            if(this.lamount === '' || this.lamount <= 0){
                this.$notification.info(this.$t('r.a38'));
                return false;
            }
            if(this.wpwprice === '' || this.wpwprice <= 0){
                this.$notification.info(this.$t('r.a39'));
                return false;
            }
            if(this.lwprice === '' || this.lwprice <= 0){
                this.$notification.info(this.$t('r.a40'));
                return false;
            }
            if(this.wptype === 1 && (this.wpprice === '' || this.wpprice <= 0)){
                this.$notification.info(this.$t('r.a41'));
                return false;
            }
            if(this.lptype === 1 && (this.lprice === '' || this.lprice <= 0)){
                this.$notification.info(this.$t('r.a42'));
                return false;
            }
            const form = {
                cid: this.position.position[this.pindex]['id'],
                profitType: this.wptype,
                profitPprice: this.wptype === 0 ? this.wpwprice : this.wpprice,
                profitPwprice: this.wpwprice,
                profitAmount: this.wamount,
                loseType: this.lptype,
                losePprice: this.lptype === 0 ? this.lwprice : this.lprice,
                losePwrice: this.lwprice,
                loseAmount: this.lamount
            };

            this.loading = true;
            contractApi.limitProfit(form).then(res=>{
               this.loading = false;
                this.profitLossPop = false;
                this.$notification.info(res.message);
                if(res.code === 200){
                    this.getPosition();
                }
            });
        },
        llstopChange(e){
            //止盈止损的滑动数量计算
            let amount = this.position.position[this.pindex]['amount'] - this.position.position[this.pindex]['close'];
            this.lamount = parseFloat(amount * (e/100)).toFixed(8);
        },
        lsliderChange(e){
            //止盈止损的滑动数量计算
            let amount = this.position.position[this.pindex]['amount'] - this.position.position[this.pindex]['close'];
            this.wamount = parseFloat(amount * (e/100)).toFixed(8);
        },
        getPositionPamount(new_price){
            //获取平仓的持仓盈亏
            if(this.pamount === ''){
                return '--';
            }
            let amount = this.pamount;
            let o = parseFloat(this.position.position[this.pindex]['price']);
            let newPrice = this.pprice === '' ? parseFloat(_ticker['c']):parseFloat(this.pprice);
            if (parseInt(this.position.position[this.pindex]['direction']) === 1) {
                return this.$util.thousandSeparator(amount * (newPrice - o), 4);
            } else {
                return this.$util.thousandSeparator(amount * (o - newPrice), 4);
            }
        },
        psliderChange(e){
            //平仓滚动条滑动数量计算
            let amount = this.position.position[this.pindex]['amount'] - this.position.position[this.pindex]['close'];
            this.pamount = parseFloat(amount * (e / 100)).toFixed(8);
        },
        tabChange(e){
            //订单tab切换数据加载
            this.tabPanelIndex = parseInt(e);
            this.pindex = false;
            if(parseInt(e) === 1){
                this.getPosition();
            }
            if(this.tabPanelIndex === 4 || this.tabPanelIndex === 5){
                page = 1;
                this.history = [];
                this.getHistory();
            }
        },
        getHistory(){
            contractApi.getHistory(this.current['id'],page).then(res=>{
                if(res.code === 200){
                    if (page === 1) {
                        this.history['entrust'] = res.data['entrust'];
                        this.history['history'] = res.data['history'];
                        this.history['profit'] = res.data['profit'];
                    } else {
                        // 否则，为加载更多操作，追加数据
                        this.history['entrust']['list'] = this.history['entrust']['list'].concat(res.data['entrust']['list']);
                        this.history['history']['list'] = this.history['history']['list'].concat(res.data['history']['list']);
                        this.history['profit']['list'] = this.history['profit']['list'].concat(res.data['profit']['list']);
                    }
                }
            });
        },
        closePositions(){
            if (this.pamount === '' || parseFloat(this.pamount) <= 0) {
                this.$notification.info(this.$t('r.a43'));
                return false;
            }
            if(this.tabIndex === 0 && (this.pprice === '' || this.pprice <= 0)){
                this.$notification.info(this.$t('r.a43'));
                return false;
            }

            const form = {
                id: this.position.position[this.pindex]['id'],
                pamount: this.$util.str2number(this.pamount),
                ptype: this.tabIndex === 0 ? 2 : 1,
                price: this.tabIndex === 0 ? this.pprice : _ticker['c'],
                wprice: 0
            };
            this.loading = true;
            let amount = this.position.position[this.pindex]['amount'] - this.position.position[this.pindex]['close'];
            contractApi.closeOrder(form).then(res => {
                this.loading = false;
                this.closePotion = false;
                try {
                    if (res.code !== 200) {
                        this.$notification.info(res.message);
                    }
                } catch (e) {
                    console.log(e);
                }
                if(this.tabIndex === 1){
                    if(parseFloat(form['pamount']) >= parseFloat(amount)){
                        this.position.position = this.position.position.filter((item)=>{
                            return parseInt(item['id']) !== parseInt(form['id']);
                        });
                    }else{
                        this.position.position[this.pindex]['close'] = amount - parseFloat(this.pamount);
                    }
                }
                this.pindex = false;
            }).catch((e) => {
                console.log(e);
                this.loading = false;
                this.closePotion = false;
                this.$notification.info(e);
            })
        },
        lightClose(){
            //闪电平仓
            this.loading = true;
            const form = {
                id : this.position.position[this.pindex]['id'],
                pamount : parseFloat(parseFloat(this.position.position[this.pindex]['amount']) - parseFloat(this.position.position[this.pindex]['close'])).toFixed(8),
                ptype:1,
                price : this.current['new_price'],
                wprice : 0
            };
            contractApi.closeOrder(form).then(res=>{
                this.loading = false;
                this.flashClose = false;
                this.$notification.info(res.message);
                if(res.code === 200){
                    this.pindex = false;
                    this.position.position = this.position.position.filter((item)=>{
                        return parseInt(item['id']) !== parseInt(form['id']);
                    });
                }
            })
        },
        getMarginRate(mt,m,p){
            //获取保证金率，全仓  : 本金-亏损/保证金 ,亏损/保证金
            if(parseInt(mt) === 1){
                return this.$util.thousandSeparator((parseFloat(this.balance.legal_balance) - parseFloat(p)) / parseFloat(m) * 100);
            }
            return this.$util.thousandSeparator(parseFloat(p) / parseFloat(m) * 100);
        },
        calacPosition(ticker){
            try{
                let position = this.position.position;
                if (position.length <= 0) {
                    return false;
                }
                for (let i = 0; i < position.length; i++) {
                    if (parseInt(position[i]['currency_id']) === parseInt(this.current['id'])) {
                        let item = position[i]
                        let amount = parseFloat(item['amount']) - parseFloat(item['close']);
                        let o = parseFloat(item['price']);
                        let newPrice = parseFloat(_ticker['c']);
                        let p = 0;
                        if (parseInt(item['direction']) === 1) {
                            p = amount * (newPrice - o);
                        } else {
                            p = amount * (o - newPrice);
                        }
                        item['p'] = this.$util.thousandSeparator(p ,4);
                        //rate
                        let pr = 0;
                        if (parseInt(item['direction']) === 1) {
                            pr = item.amount * (newPrice - item.price) / item.margin * 100;
                        } else {
                            pr = item.amount * (item.price - newPrice) / item.margin * 100;
                        }
                        item['rate'] = pr > 0 ? '+' + this.$util.thousandSeparator(pr, 2) : this.$util.thousandSeparator(pr)

                        let mr = 0;
                        if (parseInt(item['margin_type']) === 1) {
                            mr = (parseFloat(this.balance.legal_balance) + p) / item['margin'] * 100
                        } else {
                            mr = (parseFloat(item['margin']) + p) / parseFloat(item['margin']) * 100;
                        }
                        item['mr'] = this.$util.thousandSeparator(mr, 2);
                        position[i] = item;
                    }
                }
                this.position.position = position;
            }catch (e){}
        },
		//美股
		calacPositions(ticker) {
			//console.log(this.position.position);
			try{
			    let position = this.position.position;
				//var filteredPositions = position.filter(item => item.cm_id == 5);
			    if (position.length <= 0) {
			        return false;
			    }
			    for (let i = 0; i < position.length; i++) {
					//console.log(parseInt(position[i]['currency_id']));
					//console.log(parseInt(this.current['id']));
			        if (parseInt(position[i]['currency_id']) === parseInt(this.current['id'])) {
			            let item = position[i]
			            let amount = parseFloat(item['amount']) - parseFloat(item['close']);
			            let o = parseFloat(item['price']);
			            let newPrice = parseFloat(_ticker['c']);
			            let p = 0;
			            if (parseInt(item['direction']) === 1) {
			                p = amount * (newPrice - o);
			            } else {
			                p = amount * (o - newPrice);
			            }
			            item['p'] = this.$util.thousandSeparator(p ,4);
			            //rate
			            let pr = 0;
			            if (parseInt(item['direction']) === 1) {
			                pr = item.amount * (newPrice - item.price) / item.margin * 100;
			            } else {
			                pr = item.amount * (item.price - newPrice) / item.margin * 100;
			            }
			            item['rate'] = pr > 0 ? '+' + this.$util.thousandSeparator(pr, 2) : this.$util.thousandSeparator(pr)
			
			            let mr = 0;
			            if (parseInt(item['margin_type']) === 1) {
			                mr = (parseFloat(this.balance.legal_balance) + p) / item['margin'] * 100
			            } else {
			                mr = (parseFloat(item['margin']) + p) / parseFloat(item['margin']) * 100;
			            }
			            item['mr'] = this.$util.thousandSeparator(mr, 2);
			            position[i] = item;
			        }
			    }
			    this.position.position = position;
			}catch (e){}
		},
        getPosition(){
            contractApi.getPositionList(this.current['id']).then(res => {
                if (res.code === 200) {
                    let position = res.data.position;
                    position.map((item) => {
                        item['p'] = 0;
                        return item;
                    });
                    res.data.position = position;
                    this.position = res.data;
                    this.getMuiltByPosition();
                } else {
                    this.position = {
                        position: [],
                        totalAmount: []
                    };
                }
            });
        },
        getMuiltByPosition() {
            if (this.position.position.length > 0) {
                this.leverVal = this.position.position[0]['muilt'];
                this.$util.setCache('futures-lever',this.leverVal);
                this.tmargin = this.position.position[0]['margin_type'];
                this.positionModel = parseInt(this.tmargin) === 1 ? 0 : 1;
                this.$util.setCache("positionModel",this.positionModel);
            }
        },
        submitOrder(direction,show=true){
            if(this.amount === '' || this.amount <= 0){
                this.loading = false;
                return false;
            }
            if(!this.balance || this.balance.legal_balance <= 0){
                this.loading = false;
                this.$notification.info(this.$t('r.a44'));
                return false;
            }
			
            if(parseFloat(this.getMarginAmount(0)) > parseFloat(this.balance.legal_balance)){
                this.loading = false;
                this.$notification.info(this.$t('r.a44'));
                return false;
            }
            if(parseFloat(this.getMarginAmount(0)) < parseFloat(this.current['min_amount'])){
                this.loading = false;
                this.$notification.info(this.$t('r.a44')+">"+this.current['min_amount']+"USD");
                return  false;
            }
			
            if(this.modeIndex === 2 && this.limitType === 0 && this.wprice === ''){
                this.loading = false;
                this.$notification.info(this.$t('r.a45'));
                return  false;
            }
            if(this.modeIndex === 1 && this.amount === ''){
                this.loading = false;
                this.$notification.info(this.$t('r.a46'));
                return  false;
            }
			
            this.submitDirection = parseInt(direction);
            this.loading = true;
            let tip = this.$util.getCache('tip','');
            if(show && tip !== '' && parseInt(tip) === 0){
                this.doSubmit();
            }else{
                if(show){
                    this.placeOrder = true;
                }else{
                    //直接提交订单
                    if(this.checked){
                        this.$util.setCache('tip',0);
                    }
                    this.doSubmit();
                }
            }
        },
        doSubmit(){
            let uamount = 0;
            //开仓头寸计算
			
            if (this.modeIndex === 1) {
                uamount = this.amounType === 0 ?
                    this.$util.str2number(this.$util.thousandSeparator(this.amount * this.current['new_price'], 4)) :
                    this.amount;
            }
            if (this.modeIndex !== 1) {
                const order_price = this.modeIndex === 0 ? this.$util.str2number(this.price) : this.$util.str2number(this.wprice);
                uamount = this.amounType === 0 ?
                    this.$util.str2number(this.$util.thousandSeparator(this.amount * order_price, 4)) :
                    this.amount;
            }

            //开仓数量计算
            let amount = 0;
            if (this.amounType === 0) {
                amount = this.amount;
            } else {
                if (this.modeIndex === 1) {
                    amount = parseFloat(parseFloat(this.amount) / parseFloat(this.current['new_price'])).toFixed(this
                        .current['trade_decimals'])
                }
                if (this.modeIndex === 2) {
                    amount = parseFloat(parseFloat(this.amount) / parseFloat(this.$util.str2number(this.wprice)))
                        .toFixed(this.current['trade_decimals'])
                }
            }

            let form = {
                cm_id: this.cm_id,
                currency_id: this.current['id'],
                direction: this.submitDirection,
                muilt: this.leverVal,
                order_type: this.modeIndex === 0 ? 2 : this.modeIndex === 1 ? 1:3,
                uamount: uamount,
                amount: amount,
                margin_type: this.positionModel === 0 ? 1 : 2
            };
			//
            if (this.modeIndex === 0) {
                form['price'] = this.$util.str2number(this.price); //限价
            }
            if (this.modeIndex === 1) {
                form['price'] = this.$util.str2number(this.current['new_price']); //市价
            }
            if (this.modeIndex === 2) {
                form['price'] = this.$util.str2number(this.price === '' ? this.current['new_price'] : this.price); //条件单的委托价格
                form['wprice'] = this.$util.str2number(this.wprice); //条件单的触发价格
            }

            // if (this.stopProfit) {
            //     form['stop_up'] = (this.price_up != '' && this.price_up > 0) ? this.price_up : 0;
            //     form['stop_down'] = (this.price_down != '' && this.price_down > 0) ? this.price_down : 0;
            // }
            this.placeOrder = false;
			//console.log(form);return false;
            contractApi.submitOrder(form).then(res=>{
				
                this.loading = false;
                if(res.code !== 200){
                    this.$notification.info(res.message);
                    return false;
                }
                this.amount = '';
                this.price = '';
                this.wprice = '';
                this.rate = 0;
                this.getPosition();
                setTimeout(()=>{
                    this.getBalance();
                },1500);
                this.$notification.info(this.$t('r.a47'));
            }).catch((e)=>{
                this.$notification.info(e);
            });
        },
        getMarginAmount(new_price){
            if(this.amount === '' || this.amount <= 0){
                return '0.00';
            }
            if(this.modeIndex === 0){
                return this.amounType === 0 ?
                    this.amount / this.leverVal * parseFloat(_ticker['c']):
                    this.amount / this.leverVal
            }else{
                return this.price === '' ?
                    this.amounType === 0 ?
                        this.amount / this.leverVal * parseFloat(_ticker['c']):
                        this.amount / this.leverVal:
                    this.amounType === 0 ?
                        this.amount / this.leverVal * parseFloat(this.price):
                        this.amount / this.leverVal
            }
        },
        getTopAmount(d = 1,new_price){
			//console.log(_ticker['c']); return false;
            if(!_ticker){
                return '0.00';
            }
            if (!this.balance || this.balance.legal_balance <= 0) {
                return '0.00';
            }
            //1 多 2空
            //开多减去多担仓位的数量
            //开空减去空担仓位的数量
            let pamount = this.getDirectionAmount(d);
            let aamount = 0;
            if (this.modeIndex === 0) {
                aamount = this.balance.legal_balance / parseFloat(_ticker['c']) * this.leverVal;
            } else {
                aamount = this.balance.legal_balance / (this.price !== '' ? this.$util.str2number(this.price) : parseFloat(_ticker['c'])) * this.leverVal;
            }
            aamount = parseFloat(aamount * (1-parseFloat(this.current['fee_open']))).toFixed(this.current['decimals']);
            if (this.amounType === 0) {
                return aamount - pamount > 0 ? parseFloat(aamount - pamount).toFixed(this.current['decimals']) : 0;
            } else {
                let uamount = aamount - pamount > 0 ?
                    parseFloat((aamount - pamount) * (this.modeIndex === 0 ? parseFloat(_ticker['c']) : (this.price !== '' ? this.$util.str2number(this.price) : parseFloat(_ticker['c'])))).toFixed(this.current['decimals']) : 0;
                // if(uamount > 500000){
                // 	return '500000.00';
                // }
                return uamount;
            }
        },
        getDirectionAmount(d) {
            let list = this.position.position.filter(item => item.direction === d);
            let amount = 0
            if (list.length > 0) {
                for (let i = 0; i < list.length; i++) {
                    amount += parseFloat(list[i]['amount']) - parseFloat(list[i]['close'])
                }
            }
            return amount;
        },
        sliderChange(e){
			
			console.log(e);
            if(this.btnActive === 0){
                this.rate = e;
                let t = this.getTopAmount(1);
                let d = this.getTopAmount(2);
				console.log(t);
				console.log(d);
                this.amount = this.$util.str2number(this.$util.float(parseFloat((t > d ? t : d) * (e / 100)).toFixed(8)));
            }else{
                this.rate = e;
                let t = this.getPositionAmount(1);
                let d = this.getPositionAmount(2);
                this.amount = this.$util.str2number(this.$util.float(parseFloat((t > d ? t : d) * (e / 100)).toFixed(8)));
            }
        },
        getBalance(){
			//console.log(50);
            if(this.isLogin){
				let cm_id = this.current['id']>10000?5:2;//5是美股
                contractApi.getBalance(cm_id,this.current['id']).then(res=>{
                    this.balance = res.data;
					//console.log(50);
					//console.log(this.balance.depth.data.bids[0].price);
					//this.current['new_price'] = this.balance.depth.data.bids[0].price;
                })
            }
        },
        getPositionLever(){
            let lever = this.$util.getCache('futures-lever','');
            if(lever !== ''){
                this.leverVal = parseInt(lever);
            }
        },
        setPositionLever(){
            this.$util.setCache('futures-lever',this.leverVal);
            this.leverArm = false;
        },
        getPositionModel(){
            let model = this.$util.getCache('positionModel','');
            if(model !== ''){
                this.positionModel = parseInt(model);
                if(this.positionModel === 0){
                    this.all = true;
                }else{
                    this.signal = true;
                }
            }else{
                this.positionModel = 0;
                this.all = true;
                this.signal = false;
            }
        },
        confirmPositionChange(){
              this.$util.setCache("positionModel",this.positionModel);
              this.marginModel = false;
        },
        positionChange(e){
            //console.log(e);
            this.positionModel = e;
        },
        getContractCurrency(){
            currencyApi.contractCurrencyList().then(res=>{
                this.currency = res.data;
				//console.log(this.currency);
                this.getCurrentCurrency();
            });
        },
        getCurrentCurrency(){
			//console.log(50);
            let current = this.$util.getCache(currentKey,'');
			
            if(current !== ''){
                this.current = JSON.parse(current);
				console.log( this.current);
            }else{
                this.current = this.currency[0]['currency'][0];
                this.$util.setCache(currentKey,JSON.stringify(this.current));
            }
            this.createCharts();
            this.connectSocket();
            this.getCurrencyInfo();
        },
        getCurrencyInfo(){
			let cm_id = this.current['id']>10000?5:2;//5是美股
			
			//console.log(this.current['id']);
            currencyApi.getCurrencyInfo(this.current['id'],cm_id).then(res=>{
                this.currencyInfo = res.data.currency_info != null ? JSON.parse(res.data.currency_info):false;
				console.log(this.currencyInfo);
				//this._ticker = this.currencyInfo;
				//this.current['new_price'] = 50;
				//console.log(this._ticker);
               
            })
        },
		
		//点击切换数字或美股
        setCurrentCurrency(item){
			console.log(item);
			this.cm_id = item.cm_id;
			item.new_price = this.cm_id;
            this.$util.setCache(currentKey,JSON.stringify(item));
            this.current = item;
			//console.log(this.current);
            klineCharts.setSymbol({
                ticker: item['symbol']+'USD',
                pricePrecision:item['decimals'],
                //logo:item['icon'],
                type:""
            });
            _ticker = false;
            this.subscribe();
            this.trades = [];
            this.getPosition();
            this.amount = '';
            this.price = '';
            this.wprice = '';
        },
        tableToggle(item, index) {
            this.tableActive = index;
            this.coinName = item.name;
            this.coinType = item.type;
        },
        aboutCoinShow() {
            this.aboutCoin = true;
        },
        setNum() {
            // this.timer = setInterval(() => {
            //     this.List.forEach(item => {
            //         item.price = Math.random().toFixed(5)
            //     })
            // }, 2000)
        },
        numSelect(e) {
            this.decimal = e;
        },
        toDetail() {
            this.$router.push("/HomeView/futures/futuresData")
        },
        priveChange(e) {
            console.log(e)
            this.priceTypeVal = e;
        },
        onMessage(data){
			//console.log(data);
            if(data.type === 203){
                const date = new Date(data.data.data.E);
                let trades = this.trades;
                if (trades.length > 50) {
                    trades = trades.slice(1);
                }
                let h = date.getHours();
                let m = date.getMinutes();
                let s = date.getSeconds();
                trades.push({
                    time: (h<10 ? '0'+h:h) + ':' + (m<10?'0'+m:m) + ':' + (s<10?'0'+s:s),
                    price: data.data.data.p,
                    vol: data.data.data.q,
                    type : data.data.m
                });
                this.trades = trades;
            }
            if(data.type === 202){
                this.market = data.data.data;
                if (timestamp === 0) {
                    timestamp = this.market['T'];
                }
                if (timestamp !== this.market['T']) {
                    timestamp = this.market['T'];
                }
                if (!timecaluc) {
                    this.calacTime();
                }
            }
            if(data.type === 200 ){
                _ticker = this.$util.getCurrentTicker(this.current['id'], data.data.data);
            	//console.log(data.data.data);
                let current = this.current;
                current['new_price'] = _ticker['c'];
                current['change_rate'] = _ticker['P'];
                current['high_price'] = _ticker['h'];
                current['low_price'] = _ticker['l'];
                current['quantity'] = _ticker['v'];
                current['quota'] = _ticker['q'];
                this.current = current;
                this.calacPosition(data.data.data);
            }
			//美股
			if( data.type === 600){
			    //_ticker = this.$util.getCurrentTicker(this.current['id'], data.data.data);
				try {
					_ticker = data.data.data[this.current['id']];
					//console.log(_ticker['c']);
					let current = this.current;
					current['new_price'] = _ticker['c'];
					current['change_rate'] = _ticker['P'];
					current['high_price'] = _ticker['h'];
					current['low_price'] = _ticker['l'];
					current['quantity'] = _ticker['v'];
					current['quota'] = _ticker['q'];
					this.current = current;
					this.calacPositions(data.data.data);
				} catch (e) {
				
				}
			    
			}
			
			
            if(data.type === 201 || data.type === 601){
				//console.log(data.data.data);
                //深度数据
                amax = 0;
                bmax = 0;
                let asks = data.data.data.a;
                let bids = data.data.data.b;
                asks.sort((a, b) => {
                    return a[1] - b[1];
                });
                asks.map((v, i) => {
                    if (amax < parseFloat(v[1])) {
                        amax = v[1];
                    }
                });

                for (let i = 0; i < asks.length; i++) {
                    asks[i][3] = parseFloat(asks[i][1] / amax).toFixed(8);
                }
                asks.reverse();
                bids.map((v, i) => {
                    if (bmax < parseFloat(v[1])) {
                        bmax = v[1];
                    }
                });
                bids.reverse();
                for (let i = 0; i < bids.length; i++) {
                    bids[i][3] = parseFloat(bids[i][1] / bmax).toFixed(8);
                }
                this.asks = asks;
                this.bids = bids;
				//console.log(this.bids[0][0]);
				this.current['new_price'] = this.bids[0][0];
            }
        },
        calacTime() {
            console.log("资金费率倒计时");
            if(timecaluc){
                clearInterval(timecaluc);
            }
            console.log(timestamp);
            timecaluc = setInterval(() => {
                const currentTimestamp = Date.now();
                const timeRemaining = timestamp - currentTimestamp;
                const hoursRemaining = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutesRemaining = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
                const secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);
                this.timeInt = (hoursRemaining < 10 ? '0' + hoursRemaining : hoursRemaining) + ':' +
                    (minutesRemaining < 10 ? '0' + minutesRemaining : minutesRemaining) + ':' +
                    (secondsRemaining < 10 ? '0' + secondsRemaining : secondsRemaining)
            }, 1000);
        },
        connectSocket(){
            if (!socket) {
                const deviceId = this.$util.getCache('deviceId');
                const url = config['BASE_WSS'] + '/?token=' + deviceId + '$contract' + '&sub=contract';
                let _this = this;
                socket = new WebSocket(url);
                socket.onopen = () => {
                    setTimeout(() => {
                        if(socketInterval){
                            clearInterval(socketInterval);
                        }
                        socketInterval = setInterval(() => {
                            if(this.userId && socket){
                                try{
                                    socket.send(JSON.stringify({
                                        event: 'updateCuserId',
                                        uid: _this.userId
                                    }));
                                }catch (e){
                                    clearInterval(socketInterval);
                                    socket = false;
                                    _this.connectSocket();
                                }
                            }
                        }, 5000);
                    }, 1500);
                    this.subscribe();
                    socket.onmessage = (data) => {
                        this.onMessage(JSON.parse(data.data));
                    }
                }
            }
        },
        subscribe(){
            socket.send(JSON.stringify({
                event: "contrade_depth_subscribe",
                cm_id: this.current['id']>10000?5:2,//5是美股
                currency_id: this.current['id']
            }));
        },
        createCharts(){
            if(klineCharts){
                return false;
            }
			
            let _this = this;
            class CustomDatafeed {
                searchSymbols(search) {
                }
                async getHistoryKLineData(symbol, period, from, to) {
                    _this.$util.setCache('local-period',JSON.stringify(period));
                    let res = await currencyApi.contractLine({
                        interval: period.text,
                        currency_id: _this.current['id'],
						cm_id:_this.current['id']>10000?5:2,//5是美股
                        limit:1000
                    });
                    let type = 'candle_solid';
                    if(period.text === '1s'){
                        type = 'area';
                    }
                    klineCharts.setStyles({
                        candle:{
                            type:type
                        }
                    });
					//console.log(32);
					//console.log(res);
                    let data = res.data.map((item)=>{
                        return {
                            timestamp:item['time'],
                            open: item['open'],
                            high: item['high'],
                            low: item['low'],
                            close: item['close'],
                            volume: item['vol'],
                            turnover:item['amount'],
                        }
                    });
                    last = data[data.length - 1];
                    return data;
                }
                subscribe(symbol, period, callback) {
					
                    // 完成ws订阅或者http轮询
                    if(!_ticker){
                        return;
                    }
                    console.log(symbol);
                    if(upset !== false){
                        clearInterval(upset);
                    }
					//console.log(period);
                    upset = setInterval(()=>{
						//console.log(_ticker);
                        if(period.text === '1s'){
							firstPrice = parseFloat(_ticker['c']);
                            //firstPrice = parseFloat(_ticker['c']?_ticker['c']:0);
                            callback({
                                timestamp:_ticker['E'],
                                open:firstPrice,
                                close:firstPrice,
                                high:firstPrice,
                                low:firstPrice,
                                volume:parseInt(_ticker['Q']),
                                turnover:parseInt(_ticker['Q']),
                            });
                        }else{
                            let minint = time[period.text] * 60 * 1000;
                            firstPrice = parseFloat(_ticker['c']);
                            let data;
                            if(parseInt(_ticker['E']) - last['timestamp'] > minint){
                                data = {
                                    timestamp:parseInt(_ticker['E']),
                                    open:firstPrice,
                                    close:firstPrice,
                                    high:firstPrice,
                                    low:firstPrice,
                                    volume:parseInt(_ticker['Q']),
                                    turnover:parseInt(_ticker['Q']),
                                };
                            }else{
                                
								data = {
									timestamp:last['timestamp'],
									open:last['open'],
									close:firstPrice,
									high:last['high'] < firstPrice ? firstPrice : last['high'],
									low:last['low'] > firstPrice ? firstPrice : last['low'],
									volume:last['volume'] + parseInt(_ticker['Q']),
									turnover:last['volume'] + parseInt(_ticker['Q']),
								};
								
                            }
                            last = data;
                           // callback(data);
                        }
                    },1000);
                }
                unsubscribe(symbol, period) {
                    clearInterval(upset);
                }
            }
            let lang = this.$i18n.locale;
            let localPeriod = this.$util.getCache('local-period','');
            if(localPeriod === ''){
                localPeriod = {
                    multiplier : 1,timespan:'minute',text:'1m'
                }
            }else{
                localPeriod = JSON.parse(localPeriod);
            }
            klineCharts = new KLineChartPro({
                container: document.getElementById('chartBox'),
                watermark:"ISITE Exchange",
                theme:this.$util.getCache('userTheme'),
                 locale:lang === 'zh' ? "zh-CN":'en-US',
                //locale: "zh-CN",
                timezone:this.$util.getCache('time_zone'),
                // 初始化标的信息
                symbol: {
                    ticker: _this.current['symbol']+'USD',
                    pricePrecision:_this.current['decimals'],
                    //logo:_this.current['icon'],
                    type:"ADRC"
                },
                // 初始化周期
                period: localPeriod,
                periods:[
                    {multiplier:0,timespan:'second',text:'1s'},
                    {multiplier:1,timespan:'minute',text:'1m'},
                    //{multiplier:5,timespan:'minute',text:'5m'},
                    {multiplier:15,timespan:'minute',text:'15m'},
                    {multiplier:30,timespan:'minute',text:'30m'},
                    {multiplier:60,timespan:'minute',text:'1H'},
                    //{multiplier:240,timespan:'minute',text:'4H'},
                    //{multiplier:720,timespan:'minute',text:'12H'},
                    {multiplier:1440,timespan:'minute',text:'1D'},
                    {multiplier:10080,timespan:'minute',text:'1W'}
                ],
                subIndicators: [],
                datafeed: new CustomDatafeed()
            });
        }
    },
    beforeUnmount() {
        try {
            console.log("beforeUnmount");
            clearInterval(socketInterval);
            socket.close();
            socket = false;
            // klineChartsl
            klineCharts = false;
            timecaluc = false;
			window.removeEventListener('resize', this.resize)
            window.removeEventListener('resize', this.checkWidthAndApplyClass);

        }catch (e){
            console.log(e);
        }
    },
}