<template>
  <a-layout class="investment">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="investment-content">
        <!--  banner    -->
        <div class="i-banner-wrap flex">
          <div class="i-banner-con flex">
            <div class="c-left flex flex-column align-start jus-center">
              <p class="color-black">Financial Overview</p>
              <p class="color-black">Diversified investment types, steady appreciation of encrypted assets, and immediate earning of returns</p>
            </div>
            <div class="c-right">
              <div class="not-log">
                <svg width="50" height="50" viewBox="0 0 66 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M52.962 3.83c3.23 4.245 2.435 10.285-1.776 13.49-4.212 3.204-10.245 2.36-13.475-1.886-3.23-4.245-2.435-10.285 1.776-13.49C43.7-1.26 49.732-.415 52.962 3.83Z"
                      fill="#9C69FF"></path>
                  <path
                      d="M23.228 29.47c-4.163-5.471-3.138-13.254 2.29-17.383 5.426-4.13 13.2-3.042 17.363 2.429 4.163 5.47 3.138 13.253-2.29 17.382-5.426 4.13-13.2 3.042-17.363-2.428Z"
                      fill="#05C3DD"></path>
                  <g filter="url(#assetLogin_svg__a)">
                    <rect x="9" y="22" width="47" height="33" rx="1.82" fill="url(#assetLogin_svg__b)"
                          fill-opacity="0.5"></rect>
                    <rect x="8.75" y="21.75" width="47.5" height="33.5" rx="2.07" stroke="#393939"
                          stroke-width="0.5"></rect>
                  </g>
                  <path fill="#05C3DD" d="M47.233 35.597H58v6.63H47.233z"></path>
                  <defs>
                    <linearGradient id="assetLogin_svg__b" x1="10.152" y1="32.033" x2="15.541" y2="7.514"
                                    gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B8B8B8"></stop>
                      <stop offset="1" stop-color="#393939" stop-opacity="0"></stop>
                    </linearGradient>
                    <filter id="assetLogin_svg__a" x="0.191" y="13.191" width="64.618" height="50.618"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.154"></feGaussianBlur>
                      <feComposite in2="SourceAlpha" operator="in"
                                   result="effect1_backgroundBlur_2497_16220"></feComposite>
                      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_2497_16220" result="shape"></feBlend>
                    </filter>
                  </defs>
                </svg>
                <!--<p>{{$t('us.a54')}}</p>-->
                <a-button class="button">{{$t('f.a5')}}</a-button>
              </div>
              <div class="r-main" v-if="false">

              </div>
            </div>
          </div>
        </div>
        <!--   menu-list  -->
        <div class="i-list-wrap flex align-center jus-center">
          <div class="i-list-con">
            <div class="c-cell flex align-start jus-bet pointer" v-for="(item,index) in list" :key="index">
              <img :src="require('@/assets/images/investment/fundImg' + item.index + '.png')">
              <div class="cell-right flex flex-column align-start">
                <div class="flex align-center">
                  <span class="_title">{{ item.title }}</span>
                  <a-tag class="tag">{{ item.tag }}</a-tag>
                </div>
                <span class="_context">{{ item.context }}</span>
                <a-button class="button" @click="routerlink(item)">Go</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import notlog from "@/components/notlog/notlog.vue";

export default {
  components: {notlog, Footer, PageNav},
  data() {
    return {
      list: [{
        index: 1,
        title: this.$t('us.z23'),
        tag: "Guaranteed",
        href: "/term",
        context: "The Benfu Wealth Management series of wealth management products is a closed-end wealth management product developed and launched by ISITE Asset Management. The product is regularly open for subscription and redemption, with guaranteed capital and good security. The product is of low risk level and suitable for investors who tend to invest in capital preservation."
      },/* {
        index: 2,
        title: "共富基金",
        tag: "中风险高收益型",
        href: "/HomeView/investment/fund",
        context: "“共富基金”系列基金产品是ISITE资管研发推出的非保本风险型半封闭式基金产品，产品流动性低，安全性中。中风险，高收益产品适合进取型投资的投资者。"
      }, {
        index: 3,
        title: "平台币质押理财",
        tag: "保本型",
        href: "",
        context: "”平台币质押理财“产品是ISITE资管研发推出的币本位保本浮动收益型封闭式理财产品，产品锁仓60天后开放赎回，属于低风险等级，适合倾向于保本型投资的投资者。"
      }, {
        index: 4,
        title: "活币宝",
        tag: "随存随取",
        href: "",
        context: "针对用户闲置代币生息需求而推出的7*24小时灵活申赎型活期理财产品，支持T+0快捷赎回和T+1正常赎回，用户可随时存币生息，每日结息复投，享受复利收益。"
      }*/]
    }
  },
  methods: {
    routerlink(item) {
      this.$router.push(item.href)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./investment.scss";
</style>