<template>
  <a-layout class="fund">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="fund-content">
        <!--  banner    -->
        <div class="banner">
          <div class="banner-content">
            <div class="_left">
              <h1>共富基金</h1>
              <p>中风险｜非保本产品</p>
              <a-button class="_btn">计算收益</a-button>
            </div>
            <div class="_right">
              <div v-if="isLogin" class="account">
                <div class="head">
                  <div class="row">
                    <span class="color-gray font-12 cursor">定期持仓估值 <icon-caret-down class="cursor"/></span>
                    <icon-eye class="cursor color-gray"/>
                  </div>
                  <div class="row">
                    <span class="font-18 color-black font-bold">-- USDT</span>
                  </div>
                  <div class="row">
                    <span class="font-12 color-gray">累计收益(USDT)</span>
                  </div>
                  <div class="row">
                    <span class="font-14 color-black">0.00000000</span>
                  </div>
                </div>
                <div class="bottom">
                  <a-button class="button">前往账户</a-button>
                  <a-button class="button">前往历史</a-button>
                </div>
              </div>
              <div v-else class="not-log">
                <svg width="50" height="50" viewBox="0 0 66 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M52.962 3.83c3.23 4.245 2.435 10.285-1.776 13.49-4.212 3.204-10.245 2.36-13.475-1.886-3.23-4.245-2.435-10.285 1.776-13.49C43.7-1.26 49.732-.415 52.962 3.83Z"
                      fill="#9C69FF"></path>
                  <path
                      d="M23.228 29.47c-4.163-5.471-3.138-13.254 2.29-17.383 5.426-4.13 13.2-3.042 17.363 2.429 4.163 5.47 3.138 13.253-2.29 17.382-5.426 4.13-13.2 3.042-17.363-2.428Z"
                      fill="#05C3DD"></path>
                  <g filter="url(#assetLogin_svg__a)">
                    <rect x="9" y="22" width="47" height="33" rx="1.82" fill="url(#assetLogin_svg__b)"
                          fill-opacity="0.5"></rect>
                    <rect x="8.75" y="21.75" width="47.5" height="33.5" rx="2.07" stroke="#393939"
                          stroke-width="0.5"></rect>
                  </g>
                  <path fill="#05C3DD" d="M47.233 35.597H58v6.63H47.233z"></path>
                  <defs>
                    <linearGradient id="assetLogin_svg__b" x1="10.152" y1="32.033" x2="15.541" y2="7.514"
                                    gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B8B8B8"></stop>
                      <stop offset="1" stop-color="#393939" stop-opacity="0"></stop>
                    </linearGradient>
                    <filter id="assetLogin_svg__a" x="0.191" y="13.191" width="64.618" height="50.618"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.154"></feGaussianBlur>
                      <feComposite in2="SourceAlpha" operator="in"
                                   result="effect1_backgroundBlur_2497_16220"></feComposite>
                      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_2497_16220" result="shape"></feBlend>
                    </filter>
                  </defs>
                </svg>
                <p>登录查看收益</p>
                <a-button class="button">登录</a-button>
              </div>
            </div>
          </div>
        </div>
        <!--    main  -->
        <div class="main-warp">
          <div class="main-warp-con">
            <div class="label">
              <p class="title">全部产品</p>
              <div class="cardList">
                <div class="item" v-for="i in 4" @click="toDetail">
                  <div class="row">
                    <div class="flex align-center">
                      <img src="@/assets/images/coin/usdt.png" alt="">
                      <span class="font-16 font-bold color-black">USDT-增强收益精选</span>
                    </div>
                    <a-tag class="tag">保本</a-tag>
                  </div>
                  <div class="price">
                    <p class="font-24 font-bold color-black">5.60% ~ 12.00%</p>
                    <p class="font-14 color-gray mt-5">收益范围</p>
                  </div>
                  <div class="row">
                    <span class="color-gray font-14">申购费率</span>
                    <span class="color-black font-14">0%</span>
                  </div>
                  <div class="row">
                    <span class="color-gray font-14">赎回费率</span>
                    <span class="color-black font-14">1%</span>
                  </div>
                  <div class="row">
                    <span class="color-gray font-14">绩效费率</span>
                    <span class="color-black font-14">5%</span>
                  </div>
                  <div class="row">
                    <span class="color-gray font-14">管理费率</span>
                    <span class="color-black font-14">1.25%</span>
                  </div>
                  <a-button class="button" @click="toDetail">申购</a-button>
                </div>
              </div>
            </div>
            <div class="label">
              <p class="title">ISITE 如何确保用户资产安全？</p>
              <div class="panel">
                <div class="item">
                  <img src="@/assets/images/term/panel-image1.svg" alt="">
                  <p>安全的资金托管方案</p>
                  <span>ISITE 平台内托管 + Copper ClearLoop 方案，兼具安全和效率</span>
                </div>
                <div class="item">
                  <img src="@/assets/images/term/panel-image2.svg" alt="">
                  <p>安全的量化策略</p>
                  <span>采用中性量化策略，经过长期验证的顶级策略供应商，专业的风控体系</span>
                </div>
                <div class="item">
                  <img src="@/assets/images/term/panel-image3.svg" alt="">
                  <p>超额储备金证明</p>
                  <span>Bitget 为用户提供超过100%的储备金证明，验证您的资金可以点击这里</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import Footer from "@/components/Footer/footer.vue";
import PageNav from "@/components/PageNav/PageNav.vue";
import notlog from "@/components/notlog/notlog.vue";

export default {
  components: {notlog, PageNav, Footer},
  data() {
    return {}
  },
  methods: {
    toTerm() {
      this.$router.push("/term")
    },
    toDetail() {
      this.$router.push("/HomeView/investment/fund/fundDetails")
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./fund.scss";
</style>